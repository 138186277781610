<template>
    <div class="min-h-screen bg-gray-900 text-white py-12">
        <div class="container mx-auto px-4">
            <h2 class="text-4xl font-bold mb-8 text-center text-blue-400">My Projects</h2>

            <div class="space-y-12">
                <div class="flex flex-col md:flex-row  bg-gray-900 rounded-lg overflow-hidden">
                    <div class="md:w-1/2 p-6">
                        <h3 class="text-2xl font-semibold mb-4 text-blue-400">Algo Adversaries</h3>
                        <p class="text-gray-300 mb-4 text-left">
                            AlgoAdversaries is a sophisticated full-stack coding challenge platform. Built with a modern
                            tech stack including React, Tailwind, Node.js, Express, and PostgreSQL. Key features include:
                            <br><br>
                                • Code editor with real-time execution and response<br>
                                • Diverse array of programming challenges across multiple difficulty levels<br>
                                • Secure user authentication and personalized progress tracking<br>
                                • RESTful API for seamless front-end and back-end communication
                        </p>
                        <a href="https://algo-adversaries.vercel.app"
                            class="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">View
                            Project</a>
                    </div>
                    <div class="md:w-1/2 border-4 border-blue-400 rounded-lg overflow-hidden">
                        <img src="Project1.jpg" alt="Project 1" class="w-full h-75 object-cover">
                    </div>
                </div>

                <!-- <div class="flex flex-col md:flex-row items-center bg-gray-800 rounded-lg overflow-hidden">
                    <div class="md:w-2/3 p-6">
                        <h3 class="text-2xl font-semibold mb-4 text-blue-300">Project Name 2</h3>
                        <p class="text-gray-300 mb-4">
                            Description of your second project. Highlight its unique aspects,
                            your role in it, and any interesting challenges you overcame.
                        </p>
                        <a href="#" class="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">View Project</a>
                    </div>
                    <div class="md:w-1/3">
                        <img src="path_to_project2_image.jpg" alt="Project 2" class="w-full h-64 object-cover">
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</template>